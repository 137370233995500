import { Pipe, PipeTransform } from '@angular/core';
import { TranslatableString } from '../../../../../src/Api/LanguageSystem/TranslatableString';

declare var language: string;

export function translate(value: TranslatableString, ...args: (string | TranslatableString)[]) {
  if (!(value instanceof TranslatableString)) {
    try {
      value = <TranslatableString>TranslatableString.fromJson(value);
      if (!(value instanceof TranslatableString)) {          
        return "";
      }
    } catch (error) {}
  }
  
  try {
    let translation = value.getTranslation(language);
    let currentTranslation = translation.translation;

    currentTranslation = currentTranslation.replace(/\$\d+/, match => {
      let index = Number(match.slice(1));
      if (typeof args[index] == "string") {
        return args[index] as string;
      } else {
        return this.transform(args[index] as TranslatableString);
      }
    });

    return currentTranslation;
  } catch (error) {
    return "";
  }
}

@Pipe({
  name: 'tr2',
  pure: false
})
export class Tr2Pipe implements PipeTransform {
  constructor() {
  }

  transform(value: TranslatableString, ...args: (string | TranslatableString)[]): string {
    if (!(value instanceof TranslatableString)) {
      try {
        value = <TranslatableString>TranslatableString.fromJson(value);
        if (!(value instanceof TranslatableString)) {          
          return "";
        }
      } catch (error) {}
    }
    
    try {
      let translation = value.getTranslation(language);
      let currentTranslation = translation.translation;

      currentTranslation = currentTranslation.replace(/\$\d+/, match => {
        let index = Number(match.slice(1));
        if (typeof args[index] == "string") {
          return args[index] as string;
        } else {
          return this.transform(args[index] as TranslatableString);
        }
      });
  
      return currentTranslation;
    } catch (error) {
      return "";
    }
  }
}
