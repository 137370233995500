import { NgModule } from '@angular/core';
import { AuthService } from './Services/auth.service';
import { AbiliCookieService } from './Services/abilicookie.service';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { TranslatablePipe } from './Pipes/translatable.pipe';
import { FormsModule } from '@angular/forms';
import { MarkupPipe } from './Pipes/markup.pipe';
import { MarkupDirective } from './Directives/markup.directive';
import { HeadlineDirective } from './Directives/headline.directive';
import { LanguageSelectorDirective } from './Directives/language-selector.directive';
import { FrontpageLanguageService } from './Services/frontpage-language.service';
import { DropdownModule } from 'primeng/primeng';
import { RecaptchaModule } from 'ng-recaptcha';
import { Tr2Pipe } from './Pipes/tr2.pipe';

@NgModule({
  providers: [
    AuthService,
    AbiliCookieService,
    CookieService,
    FrontpageLanguageService,
  ],
  declarations: [
    TranslatablePipe,
    Tr2Pipe,
    MarkupPipe,
    MarkupDirective,
    HeadlineDirective,
    LanguageSelectorDirective,
  ],
  imports: [
    HttpClientModule,
  ],
  exports: [
    TranslatablePipe,
    FormsModule,
    Tr2Pipe,
    MarkupPipe,
    MarkupDirective,
    HeadlineDirective,
    LanguageSelectorDirective,
    DropdownModule,
    RecaptchaModule
  ],
})
export class SharedModule {
  public static forRoot() {
    return SharedModule;
  }
}
