import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { activeLanguage } from '../Directives/language-selector.directive';
import { TranslatableString } from '../../../../../src/Api/LanguageSystem/TranslatableString';


declare var language;

@Pipe({
  name: 'translate',
  pure: false
})

export class TranslatablePipe implements PipeTransform, OnDestroy {
  public static currentLang = language || 'de';
  public currentTranslation: string = 'undefined';

  private x: Subscription;
  constructor(private cdr: ChangeDetectorRef) {
    this.x = activeLanguage.subscribe((lang) => {
      TranslatablePipe.currentLang = lang;
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy() {
    this.x.unsubscribe();
  }

  public transform(value: TranslatableString | string, force?: boolean): string {
    if (typeof value == "string") {
      return value;
    }
    if (!(value instanceof TranslatableString)) {
      try {
        value = TranslatableString.fromJson(value) as TranslatableString;
        if (!(value instanceof TranslatableString)) {
          // console.warn(value, "Cannot be converted to a TranslateableString");
          return '';
        }
      } catch (error) {
        return 'undefined';
      }
    }
    if (!force && this.currentTranslation != 'undefined' && Object.keys(value.translations).length < 2) { return this.currentTranslation; } 

    let translation = value.getTranslation(TranslatablePipe.currentLang);
    // if (translation.languageCode != TranslatablePipe.currentLang) { console.warn(`Warning! String "${value.key}" is not translated for de`); }
    this.currentTranslation = translation.translation;

    return this.currentTranslation;
  }

}

export const translate = (value) => {
  if (!(value instanceof TranslatableString)) {
    try {
      value = TranslatableString.fromJson(value) as TranslatableString;
      if (!(value instanceof TranslatableString)) {
        console.warn(value, "Cannot be converted to a TranslateableString");

      }
    } catch (error) {
      return "undefined";
    }
  }
  try {
    let translation = value.getTranslation(TranslatablePipe.currentLang);
    // if(translation.languageCode != language) console.warn(`Warning! String "${value.key}" is not translated for de`);
    return translation.translation;
  } catch (error) {
    return "WRONG_OBJECT_PASSED";
  }
};
