import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslatableString } from '../../../../../src/Api/LanguageSystem/TranslatableString';

@Injectable()
export class FrontpageLanguageService {

  public data: TranslatableString[];

  private loaded = false;
  private loading;

  constructor(private http: HttpClient) {
    this.loading = this.fetch();
  }

  public getByKey(key: string): Promise<TranslatableString> {
    return new Promise<TranslatableString>(async (res, rej) => {
      if (!this.loaded) {
        await this.loading;
      }

      this.data.forEach((str) => {
        if (str.key == key) { return res(str); }
      });

      return rej('key not found');
    });
  }

  private fetch(): Promise<void> {
    return new Promise((res, rej) => {
      this.http.get('/lang/frontpage' ).subscribe((data: object[]) => {
        this.data = [];
  
        data.forEach((t) => {
          this.data.push(TranslatableString.fromJson(t) as TranslatableString);
        });

        this.loaded = true;
        
        return res();
      });
    });
  }
  
}
