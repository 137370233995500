import { defaultLanguage, LanguageCode } from './lang';
import * as xss from 'xss';

export class TranslatableString {

  public static fromString(str: string): TranslatableString | Error {
    try {
      let json = JSON.parse(str);
      return TranslatableString.fromJson(json);
    } catch (e) {
      return e;
    }
  }

  public static fromJson(json: any): TranslatableString {
    try {
      return new TranslatableString(json.key, json.translations);
    } catch (error) {
      return undefined;
    }
  }

  /**
   * @returns { boolean true } if the given Object is a TranslatableString
   * @returns { boolean false } if not
   */
  public static isValid = (val: any) => {
    
    // check if the value has the necessary parameters with the right type
    if (val.key && typeof val.key == 'string') {
      val.key = xss.escapeHtml(val.key);

      if (val.translations) {
        let translations: any[] = Object.values(val.translations);
        if (translations.length) {
          for (let translation of translations) { 
            if (typeof translation !== 'string') { 
              return false;
            } else {
              translation = xss.escapeHtml(translation);
            }
          }
          return true;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } 
    
    val = undefined;
    
    return false;
  }

  public key: string;
  public translations: TranslatableString.ITranslatedString;

  constructor(key: string, translations?: TranslatableString.ITranslatedString);
  constructor(key: string, de: string, fr?: string);
  constructor(key: string, tr?: any, fr?: string) {
    this.key = key;
    if (tr && typeof tr == 'string') {
      this.translations = {};
      this.translations.de = tr;
      if (fr) {
        this.translations.fr = fr;
      }
    } else if (typeof tr == 'object') {
      this.translations = tr;
    } else {
      this.translations = {};
    }
  }

  /**
   * @param lang a language code defined in ISO 639-1. Usually you can use languageCodes defined here 
   * @returns An object of Type ITranslation
   */
  public getTranslation(lang: LanguageCode | string, returnKey?: boolean): TranslatableString.ITranslation  {
    if (this.translations[lang]) {
      return { languageCode: lang, translation: this.translations[lang] };
    }

    if (this.translations[defaultLanguage]) {
      return { languageCode: defaultLanguage, translation: this.translations[defaultLanguage] };
    }
    
    return { languageCode: 'not_translated', translation: returnKey ? this.key : '' };
  }

  /**
   * This Method does not change anything in the Database. 
   * @param languageCode a language code defined in ISO 639-1. Usually you can use languageCodes defined here 
   * @param value The translated String
   */
  public setTranslation(lang: LanguageCode | string, value: string): TranslatableString {
    this.translations[lang] = value;
    return this;
  }

  /**
   * 
   * @param langCode a language code defined in ISO 639-1. Usually you can use languageCodes defined here 
   * @returns a new TranslatableString, containing only the translation for langCode
   */
  public reduce(langCode: LanguageCode | string, includeDefaultLanguage: boolean = true): TranslatableString {
    let translations: any = {};
    translations[langCode] = this.translations[langCode];
    if (includeDefaultLanguage) {
      translations[defaultLanguage] = this.translations[defaultLanguage];
    } 
    return new TranslatableString(this.key, translations);
  }

  /**
   * Returns an Object containing a key (String) and the translations (Object: ITranslatedString)
   */
  public toJson(): object {
    let obj: any = {};
    obj.key = this.key;
    obj.translations = this.translations;
    return obj;
  }

  public toString(): string {
    return this.toJson().toString();
  }
}

export namespace TranslatableString {
  
  export interface ITranslatedString {
    [languageCode: string]: string;
  }
  
  export interface ITranslation {
    languageCode: string;
    translation: string;
  }
  
  /**
   * This is a mongoose schema setter.
   * @example Is in /Util/TranslatableString.ts
   * @param v Gets automaticly added by mongoose 
   */
  export function mongoose_getter(v: TranslatableString) {
    return TranslatableString.fromJson(v);
  }
  
  /**
   * This is a mongoose schema getter.
   * @example Is in /Util/TranslatableString.ts
   * @param v Gets automaticly added by mongoose 
   */
  export function mongoose_setter(cc: any): TranslatableString | Error {
    try {
      return cc.toJson();
    } catch (error) {
      return cc;
    }
  }
}
