import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthService {

  public customer: string;
  public customers: any[] = [];

  constructor(private http: HttpClient, private cookies: CookieService, private cookie: CookieService) { }

  public logout() {
    this.cookies.delete('Authorization', '/', window.location.hostname);

    setTimeout(() => {
      window.location.href = '/logmeout';
    }, 50);
  }

  public selectCustomer(customer?) {
    if (this.customer != (customer || {})._id) { return; }

    let currentCustomer = this.customer;
    if (customer) {
      this.customer = customer._id;
      this.cookie.set('activecustomer', btoa(this.customer), null, '/', window.location.hostname);
    } else {
      this.customer = undefined;
      this.cookie.delete('activecustomer', '/', window.location.hostname);
    }

    if (currentCustomer != this.customer) {
      window.postMessage("changed customer", window.location.origin);
    }
  }

  public loadCustomers() {
    this.http.get('/customers').subscribe((data: any) => {
      this.customers = data.customers || [];
      let hasActive = this.customers.some((customer) => this.customer == customer._id);
      if (!hasActive) {
        this.customer = undefined;
        this.cookie.delete('activecustomer', '/', window.location.hostname);
      }
    });
  }

  public getCurrentUser(): User {
    const token = this.getToken();

    try {
      if (token) {
        let payload = token.split('.')[1];
        let payloadParsed = JSON.parse(decodeURIComponent(escape(atob(payload))));

        let user = new User();
        user.entity = payloadParsed.entity;
        user.entity_type = payloadParsed.entity_type;
        user.email = payloadParsed.email;
        user.email_verified = payloadParsed.email_verified;
        user.roles = payloadParsed.roles;
        user.permissions = payloadParsed.permissions;
        user.abilities = payloadParsed.abilities;
        user.branche = payloadParsed.branche;
        user.auth = payloadParsed.auth;

        return user;

      }
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  public changePassword(oldPW: string, newPW: string) {
    return this.http.post('/changepassword', { old: oldPW, new: newPW });
  }

  private getToken(): string {
    return this.cookies.get('Authorization');
  }

}

/**
 * IMPORTANT
 * Methods not tested
 */
class User {
  public email: string;
  public email_verified: boolean;

  public entity_type: string;
  public entity: IConsultant | ICompany | IEndUser | any;

  public roles;
  public permissions;
  public abilities;
  public branche: string;
  public auth: boolean;

  public hasRole(role): boolean {
    return this.roles.some((r) => r.name.key == role);
  }

  public hasPermission(childID: string, childType, ability): boolean {
    for (let permission of this.permissions) {
      if (permission.child.entityID == childID && permission.child.entityType == childType) {
        for (let xability of permission.abilities) {
          if (xability.name.key == ability) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public hasAbility(ability: EAbility) {
    for (let currentAbility of this.abilities) {
     if (currentAbility.name.key == ability) { return true; }
    }
    return false;
  }
}

export enum EAbility {
  test_ability = 'system_ability_test',
  test_ability_2 = 'system_ability_test_2',
  useBackpackGeneric = 'system_use_backpack_generic',
  useBackpackBildung = 'system_use_backpack_bildung',
  useCompassGeneric = 'system_use_compass_generic',
  useCanvasGeneric = 'system_use_canvas_generic',
  addCustomers = 'add_customers',
  addHotels = 'add_hotels'
}

export interface IEndUser {
  firstName: string;
  lastName: string;
}

export interface IConsultant {
  firstName: string;
  lastName: string;
}

export interface ICompany {
  name: string;
}
