import { Component } from '@angular/core';
import { AbiliCookieService } from '../../Services/abilicookie.service';
import { AuthService } from '../../Services/auth.service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent {
  constructor(private cs: AbiliCookieService, private auth: AuthService) {
    if (this.cs.getCookie('AllowCookies') === 'true') {
      this.injectAnalytics();
    }
  }

  get showBanner(): boolean {
    if (this.cs.getCookie('AllowCookies').length) {
      return false;
    } else if (!!this.auth.getCurrentUser()) {
      return false;
    } else {
      return true;
    }
  }

  public deny() {
    this.cs.setCookie('AllowCookies', 'false', 300);
  }

  public accept() {
    this.cs.setCookie('AllowCookies', 'true', 300);
    this.injectAnalytics();
  }

  public injectAnalytics() {
    this.clearAnalytics();

    const parent = document.getElementById('analytics');
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://www.googletagmanager.com/gtag/js?id=UA-116320245-1';
    parent.appendChild(s);
  }

  public clearAnalytics() {
    document.getElementById('analytics').innerHTML = '';
  }
}
