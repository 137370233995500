
/**
 * Exports everything needed for LanguageSystem under @abilicor/api/lang
 */

export enum LanguageCode { 
  german = "de", 
  french = "fr", 
  english = "en",
  undefined = 'not_translated' 
}

export const defaultLanguage = LanguageCode.german;
