import { OnInit } from '@angular/core';
import {
  Directive,
  ElementRef,
  forwardRef
} from '@angular/core';

@Directive({
  selector: '[headline]'
})
export class HeadlineDirective implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.run();
  }
  
  run() {
    let dom: HTMLElement = this.el.nativeElement;
    let text: string = dom.innerHTML;
    let newText: string = text.replace(/\[\[/g, '<span class="d-inline-block g-color-primary">');
    newText = newText.replace(/\]\]/g, '</span>');
    dom.innerHTML = newText;
  }

}