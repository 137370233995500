import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoggedInGuard } from './Shared/Guards/logged-in.guard';
import { BackendRootComponent } from './Plattform/Shared/Components/backend-root/backend-root.component';

const routes: Routes = [
  { path: '', component: AppComponent, children: [
    { path: '', loadChildren: './Frontpages/Main/main.module#MainModule' },
    { path: 'plattform', canActivate: [LoggedInGuard], loadChildren: './Plattform/plattform.module#PlattformModule'},
    {
      path: 'admin', canActivate: [LoggedInGuard], children: [
        { path: '', loadChildren: './Admin/admin.module#AdminModule' }
      ]
    }
  ] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
