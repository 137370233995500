import { Directive, OnInit, ElementRef, OnChanges, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[markup]'
})
export class MarkupDirective implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.run();
  }

  run() {
    let dom: HTMLElement = this.el.nativeElement;
    let text: string = dom.innerHTML += "";
    
    text = text.replace(/\[\[/g, '<span class="d-inline-block g-color-primary">');
    text = text.replace(/\]\]/g, '</span>');

    text = text.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
    text = text.replace(/__(.*?)__/g, "<u>$1</u>");
    text = text.replace(/~~(.*?)~~/g, "<i>$1</i>");
    text = text.replace(/--(.*?)--/g, "<del>$1</del>");
    text = text.replace(/<<(.*?)>>/g, "<a href='$1'>Link</a>");

    dom.innerHTML = text;
  }
  
}
