import { Directive, ElementRef, Input } from '@angular/core';
import { Subject } from 'rxjs';
declare var $: any;

export const activeLanguage = new Subject<string>();
declare var language: string;

@Directive({
  selector: 'li[languageSelector]'
})

export class LanguageSelectorDirective {

  private isOpen: boolean = false;

  @Input() set open(val: boolean) {
    this.isOpen = val;
    this.render();
  }

  private languages = {
    de: 'Deutsch',
    fr: 'français',
    // en: 'Englisch'
  };

  private activeLang: string = localStorage.getItem('language') || 'de';

  private align: string = 'left';
  private classname: string = '';

  @Input('box-align') private set s_align(val: string) {
    this.align = val;
    this.render();
  }
  @Input('link-class') private set s_classname(val: string) {
    this.classname = val;
    this.render();
  }

  constructor(private el: ElementRef) {
    this.render();

    window.addEventListener('click', (event) => {
      let $target = $(event.target);
      if ($target.parents('li[languageselector]').length == 0 && !$target.hasClass('dropdown-link')) {
        // $(element.querySelector('a')).removeClass('open');
        this.isOpen = false;
        this.render();
      }
    });
  }

  private render() {
    let element = this.el.nativeElement;

    // render
    element.innerHTML = `
      <a style="z-index: 201" class="dropdown-link${this.isOpen ? ' open' : ''}${this.classname ? ` ${this.classname}` : ''}">
        ${this.languages[this.activeLang]}&nbsp;<i class="fa fa-caret-${this.isOpen ? 'up' : 'down'}"></i>
        <ul style="${this.align}: 0">
          ${
            Object
              .keys(this.languages)
              .map((key) => '<li class="g-py-5 g-px-20" data-lang="' + key + '">' + this.languages[key] + '</li>')
              .join('\n')
          }
        </ul>
      </a>
    `;

    // make list interactive
    let entries = element.querySelectorAll('a ul li');
    for (let entry of entries) {
      entry.addEventListener('click', () => {
        let $el = $(entry);
        if (this.activeLang == $el.data('lang')) { return; }
        this.activeLang = $el.data('lang');
        activeLanguage.next($el.data('lang'));
        window.postMessage("changed lang: " + $el.data('lang'), window.location.origin);
        language = $el.data('lang');

        this.render();
      });
    }

    // toggle open / close
    element.querySelector('a').addEventListener('click', () => {
      this.isOpen = !this.isOpen;
      this.render();
    });
  }

}
