import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'markup'
})
export class MarkupPipe implements PipeTransform {

  transform(text: string, args?: any): any {
    text = text.replace(/\[\[/g, '<span class="d-inline-block g-color-primary">');
    text = text.replace(/\]\]/g, '</span>');

    text = text.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
    text = text.replace(/__(.*?)__/g, "<u>$1</u>");
    text = text.replace(/~~(.*?)~~/g, "<i>$1</i>");
    text = text.replace(/--(.*?)--/g, "<del>$1</del>");
    text = text.replace(/<<(.*?)>>/g, "<a href='$1'>Link</a>");
    return text;
  }

}
